import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import fetchWithAuthHeaders from '@motivo/styx/src/fetchWithAuthHeaders';
import { addBreadcrumb, SeverityLevel } from '@sentry/react';
import { generatePersistedQueryIdsFromManifest } from '@apollo/persisted-query-lists';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';

const httpLink = createUploadLink({
  uri: `${import.meta.env.VITE_API_URL}/graphql`,
  // @ts-ignore
  fetch: fetchWithAuthHeaders,
});

const breadcrumbLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { errors } = response;
    if (errors) {
      addBreadcrumb({
        type: 'error',
        category: 'graphql',
        timestamp: Date.now() / 1000,
        level: 'error' as SeverityLevel,
        message: operation.operationName,
        data: {
          error: errors[0].message,
        },
      });
    } else {
      addBreadcrumb({
        type: 'http',
        category: 'graphql',
        timestamp: Date.now() / 1000,
        data: {
          url: operation.operationName,
        },
      });
    }

    return response;
  });
});

const PERSISTED_QUERIES_MANIFEST_NAME = 'persisted-query-manifest';

const persistedQueryLink =
  import.meta.env.MODE === 'production'
    ? createPersistedQueryLink(
        generatePersistedQueryIdsFromManifest({
          loadManifest: () =>
            import(
              /* @vite-ignore */
              `../../${PERSISTED_QUERIES_MANIFEST_NAME}.json`
            ),
        }),
      )
    : new ApolloLink((operation, forward) => forward(operation));

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error) => {
      return error?.message === 'Failed to fetch' || error?.message === 'Network request failed';
    },
  },
});

export default new ApolloClient({
  link: from([retryLink, persistedQueryLink, breadcrumbLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          chatsWithUnreadMessages: {
            // Always overwrite the pre-existing list
            merge(_, incoming) {
              return incoming;
            },
          },
          upcomingSessions: {
            keyArgs: false,
            // @ts-expect-error TS(2339) FIXME: Property 'cursor' does not exist on type 'Record<s... Remove this comment to see the full error message
            merge(existing, incoming, { args: { cursor } }) {
              if (!cursor || !existing) {
                return incoming;
              }
              return {
                __typename: 'UpcomingSessionsQueryResult',
                result: [...existing.result, ...incoming.result],
                nextCursor: incoming.nextCursor,
              };
            },
          },
          chatMessages: {
            keyArgs: ['chatId'],
            // @ts-expect-error TS(2339) FIXME: Property 'cursor' does not exist on type 'Record<s... Remove this comment to see the full error message
            merge(existing, incoming, { args: { cursor } }) {
              if (!cursor || !existing) {
                return incoming;
              }
              return {
                __typename: 'ChatMessagesResult',
                result: [...incoming.result, ...existing.result],
                nextCursor: incoming.nextCursor,
              };
            },
          },
        },
      },
    },
  }),
});
