export const supervisorOnboardingPaths = {
  step_1_1: '/supervisor-onboarding/qualifications-1',
  step_1_2: '/supervisor-onboarding/qualifications-2',
  step_2: '/supervisor-onboarding/opportunities',
  step_3: '/supervisor-onboarding/availability',
  step_4: '/supervisor-onboarding/agreements',
  step_5: '/supervisor-onboarding/profile',
  step_6: '/supervisor-onboarding/payment',
  success: '/supervisor-onboarding/success',
};
