import React, { useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { EmptyOrLoadingState } from '@motivo/guanyin/src/components';
import { exchangeCodeForAccessToken } from '../auth';
import config from '../config';

export default function Callback({ fetchViewer }: { fetchViewer: () => Promise<unknown> }) {
  const [isViewerFetched, setIsViewerFetched] = useState(false);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const queryParams = queryString.parse(location.search);
      if (queryParams.code) {
        const isAdmin = !!queryParams.isAdmin;
        await exchangeCodeForAccessToken(queryParams.code as string, isAdmin);

        if (isAdmin) {
          if (config.motivoProduct === 'aphrodite')
            localStorage.setItem('motivo_user_id', queryParams.motivoUserId as string);
          if (config.motivoProduct === 'astraea')
            localStorage.setItem(
              'motivo_organization_admin_id',
              queryParams.organizationAdminId as string,
            );
        }
      }

      await fetchViewer();
      setIsViewerFetched(true);
    })();
  }, [config, location.search]);

  if (isViewerFetched) {
    const path = localStorage.getItem('redirectAfterLogin');
    localStorage.removeItem('redirectAfterLogin');
    return <Redirect to={path || '/'} />;
  }

  return <EmptyOrLoadingState loading />;
}
