import throwWithoutBreaking from '@motivo/guanyin/src/utils/throwWithoutBreaking';
import config from './config';

const customFetch = async (input: RequestInfo, body?: BodyInit) => {
  const response = await fetch(input, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body,
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  if (response.status === 204) {
    return '';
  }
  const clonedResponse = response.clone();

  try {
    return await response.json();
  } catch (e) {
    const responseText = await clonedResponse.text();
    throwWithoutBreaking(e, { extra: { responseText } });
  }
  return undefined;
};

export const expireRefreshToken = async () => {
  return customFetch(`${config.apiUrl}/auth/expireRefreshToken`);
};

export const refreshAccessToken = async () => {
  const isAdmin =
    config.motivoProduct === 'zeus' ||
    localStorage.getItem('motivo_user_id') ||
    localStorage.getItem('motivo_organization_admin_id');

  let body: {} | { isAdmin: boolean } | { isMFE: boolean } = {};
  if (isAdmin) {
    body = { isAdmin: true };
  } else if (config.motivoProduct === 'astraea') {
    body = { isMFE: true };
  }
  return customFetch(`${config.apiUrl}/auth/refreshToken`, JSON.stringify(body));
};

export const exchangeCode = async (code: string, isAdmin: boolean) => {
  return customFetch(
    `${config.apiUrl}/auth/code`,
    JSON.stringify({
      code,
      origin: window.location.origin,
      isAdmin,
      ...(config.motivoProduct === 'astraea' && !isAdmin && { isMFE: true }),
    }),
  );
};
