import { createTheme } from '@mui/material/styles';
import {
  vineyardRose,
  bluebell,
  spruce,
  maple,
  warning,
  error,
  success,
  info,
  grey,
  textColors,
  neutral,
  neutralDark,
  neutralLight,
} from './colors';

export default createTheme({
  palette: {
    primary: vineyardRose,
    secondary: bluebell,
    vineyardRose,
    bluebell,
    spruce,
    maple,
    warning,
    error,
    success,
    info,
    grey,
    text: textColors,
    neutral,
    neutralDark,
    neutralLight,
  },
  typography: {
    fontFamily: ['proxima-nova', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    display: {
      fontSize: 45,
      lineHeight: 1.5,
    },
    h1: {
      fontSize: 48,
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h2: {
      fontSize: 30,
      lineHeight: 1.5,
      fontWeight: 600,
    },
    h3: {
      fontSize: 24,
      lineHeight: 1.5,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.5,
      color: textColors.tertiary,
    },
    body3: {
      fontSize: 18,
      lineHeight: 1.5,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 'normal',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
    },
    overline: {
      fontSize: 12,
      lineHeight: 1,
      textTransform: 'none',
      letterSpacing: '0.01em',
    },
  },
  components: {
    MuiAccordion: {
      defaultProps: { variant: 'elevation' },
    },
    MuiAppBar: {
      defaultProps: { elevation: 0 },
      styleOverrides: {
        root: {
          zIndex: 1,
          border: 0,
          borderBottom: `1px ${grey[300]} solid`,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre-line',
        },
      },
    },
    MuiFormLabel: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          transition: 'color 300ms',
          marginBottom: '1rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: 'normal',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '4px',
          padding: '4px 0px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&[aria-selected="true"]': {
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 'normal',
          fontSize: 14,
          '&.Mui-selected': {
            backgroundColor: 'rgba(87, 126, 217, 0.08)',
            '&.Mui-focusVisible, &:hover': {
              backgroundColor: 'rgba(87, 126, 217, 0.12)',
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '16px',
          fontWeight: 600,
          textTransform: 'none',
          fontSize: 16,
          lineHeight: 1.5,
          textAlign: 'center',
        },
        outlined: {
          padding: '15px',
        },
        containedNeutralDark: {
          color: neutralLight.main,
          backgroundColor: neutralLight.contrastText,
        },
        containedNeutralLight: {
          color: neutralDark.main,
          backgroundColor: neutralDark.contrastText,
        },
        outlinedNeutralDark: {
          color: neutralDark.main,
          borderColor: neutralDark.main,
        },
        outlinedNeutralLight: {
          color: neutralLight.main,
          borderColor: neutralLight.main,
        },
      },
    },
    MuiLink: {
      defaultProps: { color: 'secondary', underline: 'hover', variant: 'body1' },
      styleOverrides: {
        button: {
          fontSize: '1rem',
          cursor: 'pointer',
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiPaper: {
      defaultProps: { elevation: 0, variant: 'outlined', square: false },
      styleOverrides: {
        rounded: {
          borderRadius: '4px',
        },
        outlined: {
          border: `1px ${grey[300]} solid`,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        select: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          '&.Mui-disabled': {
            opacity: 0.3,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
        head: {
          fontWeight: 700,
          borderBottom: 'none',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h1',
          h3: 'h2',
          body3: 'p',
        },
      },
    },
  },
});
