import {
  EmptyOrLoadingState,
  SettingsLayout as MoSettingsLayout,
} from '@motivo/guanyin/src/components';
import useProfileProgress from ':src/hooks/useProfileProgress';
import React, { ReactNode, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { SettingsPagesEnum } from ':src/globalConstants';
import useViewer from '../auth/hooks/useViewer';
import useFeatureFlag from '../feature-flags/hooks/useFeatureFlag';
import { notificationsPagePath } from './NotificationsPage';

export type SettingsLayoutType = {
  children: ReactNode;
};

export default function SettingsLayout({ children }: SettingsLayoutType) {
  const {
    viewer: { isSupervisor },
  } = useViewer();
  const { pathname } = useLocation();
  const data = useProfileProgress();
  const isEmbeddedZoomSessionEnabled = useFeatureFlag('embedded-zoom-session');
  const isBowenV2Enabled = useFeatureFlag('bowen-v2');

  if (!data) return <EmptyOrLoadingState loading />;

  const { missingFields, viewer } = data;
  const { stripeExpressDashboardLink, supervisorApplication } = viewer;

  const pages = [
    {
      name: SettingsPagesEnum.GENERAL,
      path: '/settings',
      isActive: pathname === '/settings',
      badgeCount: missingFields[SettingsPagesEnum.GENERAL].length,
    },
    {
      name: SettingsPagesEnum.PROFILE,
      path: '/settings/profile',
      isActive: pathname === '/settings/profile',
      badgeCount: missingFields[SettingsPagesEnum.PROFILE].length,
    },
    ...(isSupervisor || isEmbeddedZoomSessionEnabled
      ? [
          {
            name: SettingsPagesEnum.PREFERENCES,
            path: '/settings/preferences',
            isActive: pathname === '/settings/preferences',
            badgeCount: isSupervisor ? missingFields[SettingsPagesEnum.PREFERENCES].length : 0,
          },
        ]
      : []),
    ...(isSupervisor
      ? [
          {
            name: `${SettingsPagesEnum.LICENSES}${isBowenV2Enabled ? ' & Certifications' : ''}`,
            path: '/settings/licenses',
            isActive: pathname === '/settings/licenses',
            badgeCount: missingFields[SettingsPagesEnum.LICENSES].length,
          },
          {
            name: SettingsPagesEnum.PAYMENT,
            url: stripeExpressDashboardLink || '/dashboard/stripe-onboard/refresh',
            isActive: false,
            badgeCount: missingFields[SettingsPagesEnum.PAYMENT].length,
          },
          ...(supervisorApplication
            ? [
                {
                  name: SettingsPagesEnum.AGREEMENTS,
                  path: '/settings/agreements',
                  isActive: pathname === '/settings/agreements',
                  badgeCount: missingFields[SettingsPagesEnum.AGREEMENTS].length,
                },
              ]
            : []),
        ]
      : [
          {
            name: SettingsPagesEnum.PAYMENT,
            path: '/settings/payment',
            isActive: pathname.startsWith('/settings/payment'),
            badgeCount: missingFields[SettingsPagesEnum.PAYMENT].length,
          },
        ]),
    {
      name: SettingsPagesEnum.CALENDAR,
      path: '/settings/calendar',
      isActive: pathname === '/settings/calendar',
    },
    {
      name: SettingsPagesEnum.NOTIFICATIONS,
      path: notificationsPagePath,
      isActive: pathname === notificationsPagePath,
    },
  ];
  const finalPages = pages.map((page) => {
    return {
      ...page,
      badgeCount: isSupervisor ? page.badgeCount : 0,
    };
  });

  return (
    <MoSettingsLayout pages={finalPages}>
      <Suspense fallback={<EmptyOrLoadingState loading />}>{children}</Suspense>
    </MoSettingsLayout>
  );
}
