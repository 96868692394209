import { graphql } from ':src/graphql';

export const createIntroRequestMutation = graphql(`
  mutation CreateIntroRequestMutation($input: CreateIntroRequestInput!) {
    createIntroRequest(input: $input) {
      success
      error
      introRequestId
    }
  }
`);

export const updateProfileMutation = graphql(`
  mutation UpdateProfileMutation($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      success
    }
  }
`);

export const approveSuperviseeMutation = graphql(`
  mutation ApproveSuperviseeMutation($input: ApproveSuperviseeInput!) {
    approveSupervisee(input: $input) {
      success
    }
  }
`);

export const updateSupervisorBandwidthMutation = graphql(`
  mutation UpdateSupervisorBandwidthMutation($input: UpdateSupervisorBandwidthInput!) {
    updateSupervisorBandwidth(input: $input) {
      success
    }
  }
`);
