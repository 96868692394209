import auth0 from 'auth0-js';
import { ConfigType, setConfig, verifyConfig } from './config';
import { isTokenExpired } from './helpers';
import { exchangeCode, expireRefreshToken } from './services';
import { getAccessToken, setAccessToken } from './utils';

let auth0Client: auth0.WebAuth;

export function initAuth(config: ConfigType) {
  setConfig(config);
  auth0Client = new auth0.WebAuth({
    audience: config.audience,
    domain: config.domain,
    clientID: config.clientID,
    redirectUri: `${window.location.origin}/callback${
      config.motivoProduct === 'zeus' ? '?isAdmin=true' : ''
    }`,
    responseType: 'code',
    scope: 'openid profile offline_access',
  });
}

export function login(redirect?: string) {
  localStorage.setItem(
    'redirectAfterLogin',
    redirect || `${window.location.pathname}${window.location.search}`,
  );
  auth0Client.authorize();
}

export async function exchangeCodeForAccessToken(code: string, isAdmin: boolean) {
  const response = await exchangeCode(code, isAdmin);
  setAccessToken(response.access_token);
}

export function loggedIn() {
  verifyConfig();
  const token = getAccessToken();
  return !!token && !isTokenExpired(token);
}

export async function logout() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('motivo_user_id');
  localStorage.removeItem('motivo_organization_admin_id');
  await expireRefreshToken();
  return auth0Client.logout({
    returnTo: window.location.origin,
  });
}
