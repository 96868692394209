import { ApolloProvider } from '@apollo/client';
import { sentryIgnoreErrors } from '@motivo/guanyin/src/utils/sentryConfig';
import { initAuth } from '@motivo/styx/src/auth';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { BrowserClient, Feedback, getClient } from '@sentry/react';
import { extraErrorDataIntegration, captureConsoleIntegration } from '@sentry/integrations';
import App from './App';
import apolloClient from './apollo/apolloClient';

if (
  import.meta.env.VITE_POSTHOG_KEY &&
  (import.meta.env.MODE === 'production' || import.meta.env.VITE_DEV_ANALYTICS)
) {
  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: 'https://app.posthog.com',
    autocapture: false,
  });
}

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://4ce19fde8f1f46ffb261a8c86dd68787@o446946.ingest.sentry.io/5426264',
    environment: import.meta.env.MODE,
    ignoreErrors: sentryIgnoreErrors,
    beforeBreadcrumb(breadcrumb, hint) {
      const { category } = breadcrumb;
      if (category === 'xhr') return null;

      if (category === 'ui.click') {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        const nodeName = hint.event.srcElement?.nodeName;

        if (['A', 'BUTTON'].includes(nodeName)) {
          return {
            ...breadcrumb,
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            message: hint.event.srcElement?.innerText,
          };
        }
      } else if (category === 'fetch') {
        const {
          // @ts-expect-error TS(2339) FIXME: Property 'url' does not exist on type '{ [key: str... Remove this comment to see the full error message
          data: { url },
        } = breadcrumb;
        if (url.includes('/assets/') || url.endsWith('/graphql')) return null;
      }

      return breadcrumb;
    },
    beforeSend(event) {
      const client = getClient<BrowserClient>();
      const feedback = client?.getIntegration(Feedback);

      if (feedback) {
        const widget = feedback.getWidget();
        if (!widget) feedback.createWidget();
      }

      return event;
    },
    integrations: [
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        autoInject: false,
        showBranding: false,
        showName: false,
        showEmail: false,
        colorScheme: 'light',
        useSentryUser: {
          email: 'email',
          name: 'fullName',
        },
      }),
      // @ts-expect-error,
      extraErrorDataIntegration(),
      // @ts-expect-error,
      captureConsoleIntegration({ levels: ['error', 'warn'] }),
    ],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

initAuth({
  audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientID: import.meta.env.VITE_AUTH0_CLIENTID,
  apiUrl: import.meta.env.VITE_API_URL,
  motivoProduct: 'aphrodite',
});

const container = document.getElementById('app-root');
const root = createRoot(container!);
root.render(
  <ApolloProvider client={apolloClient}>
    <PostHogProvider client={posthog}>
      <Router>
        <App />
      </Router>
    </PostHogProvider>
  </ApolloProvider>,
);

if (import.meta.env.MODE === 'production') {
  window.addEventListener('error', (errorEvent) => {
    if (
      /is not a valid JavaScript MIME type/.test(errorEvent.message) ||
      /Importing a module script failed./.test(errorEvent.message)
    ) {
      window.location.reload();
    }
  });
}
