export type ConfigType = {
  audience?: string;
  domain?: string;
  clientID?: string;
  apiUrl?: string;
  motivoProduct?: 'aphrodite' | 'astraea' | 'zeus';
};

const config: ConfigType = {};

export const verifyConfig = (
  errorMessage: string = 'Styx has not been initialized. Make sure you are calling initAuth with all values from the top of App.tsx',
) => {
  const configValues = Object.values(config);
  if (configValues.length < 5 || !configValues.every((el) => !!el)) {
    throw new Error(errorMessage);
  }
};

export const setConfig = (newConfig: ConfigType) => {
  Object.assign(config, newConfig);
  verifyConfig('Not all values provided to Styx init function');
};

export default config;
